// $primary: #a51616;
$primary: #a5252a; /* MAIN COLOR */
$secondary: #a5252a; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import 'https://fonts.googleapis.com/css?family=Leckerli+One|Source+Sans+Pro';

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
	    // padding: 1.5em 2em;
		
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		@include btn2;
		display: block;
		width: 75%;
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}
.banner {
	background: url('../img/banner.jpg') no-repeat;
	background-size: cover;
	height: 100vh;
	padding: 400px 0;
	@media (max-width: 992px) {
		height: auto;
		padding: 100px 0;
	}
	@media (max-width: 768px) {
		padding: 25px 0;
	}
}
.banner2 {
	background: url('../img/banner2.jpg') no-repeat;
	background-size: cover;
	height: 100vh;
	padding: 400px 0;
	p {
		color: white;
		font-size: 22px;
	}
	@media (max-width: 992px) {
		height: auto;
		padding: 100px 0;
	}
	@media (max-width: 767px) {
		padding: 25px 0;
	}
	@media (max-width: 500px) {
		background-position: 40% 50%;
	}
}
.banner3 {
	background: url('../img/banner3.jpg') no-repeat;
	background-size: cover;
	height: 100vh;
	padding: 400px 0;
	p {
		color: white;
		font-size: 22px;
	}
	@media (max-width: 992px) {
		height: auto;
		padding: 100px 0;
	}
	@media (max-width: 768px) {
		padding: 25px 0;
	}
	@media (max-width: 600px) {
		background-position: 55% 50%;
	}
}
.logo {
	max-width: 250px;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}
.title {
	color: white;
	font-size: 70px;
	text-shadow: 2px 2px black;
}
.marginTopBtm {
	padding: 400px 0;
}
.marginTopBtm2 {
	padding: 100px 0;
}
i {
	font-size: 100px;
	color: white;
}
.bg {
	background: $secondary;
	color: white;
}
button.btn.btn-large {
	display: block;
	margin: 20px auto;
	padding: 20px 40px;
	background: $primary;
	color: white;
}
.btn2 {
  display: inline-block;
  margin: 20px auto;
  padding: 20px 40px;
  background: #fac342;
  color: white;

}
h1.gTitle {
	font-family: 'Leckerli One', cursive;
	color: #a5252a;
}
h1 {
	font-family: 'Leckerli One', cursive;
	padding: 20px 0;
}
h2,h3 {
	font-family: 'Leckerli One', cursive;
}
p {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 20px;
}
.row.bg {
	background: rgba(165,37,42,0.4);
	border-radius: 20px;
}
.nav.navbar-nav {
	margin-top: 20px;
	@media (max-width: 767px) {
		margin-top: 0;
	}
}